import "./bootstrap";

import { createApp } from 'vue/dist/vue.esm-bundler';
import VoteList from "./components/VoteList.vue";
import Vote from "./components/Vote.vue";

const App = async () => {
    const vote = createApp({});
    vote.component("votes", VoteList); 
    vote.component("vote", Vote); 
    vote.mount("#app");
};

App();

